
import { mdiEmailOutline } from '@mdi/js'
import Vue from 'vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
export default Vue.extend({
  props: {
    maxWidth: {
      type: String,
      required: false,
      default: '749px',
    },
    width: {
      type: String,
      required: false,
      default: '100%',
    },
    height: {
      type: String,
      required: false,
      default: '71px',
    },
    boxClass: {
      type: String,
      required: false,
      default: '',
    },
    btnText: {
      type: String,
      required: false,
      default: '無料で試す',
    },
  },
  data() {
    return {
      mdiEmailOutline,
      isFocus: false,
      showContactModal: false,
      contactEmail: '',
    }
  },
  methods: {
    setFocus(value: boolean) {
      this.isFocus = value
    },
    clickBar() {
      ;(this.$refs as any)?.baseMailInput?.focus()
    },
    cancel(close: any) {
      close()
    },
    submit() {
      this.showContactModal = true
    },
  },
})
