
import Vue from 'vue'
import { mdiClose } from '@mdi/js'

export default Vue.extend({
  name: 'VTailwindModal',
  inheritAttrs: false,
  data() {
    return { mdiClose }
  },
})
