
import Vue from 'vue'

export default Vue.extend({
  props: {
    emailId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      form: {
        type: '',
        company_name: '',
        name: '',
        email: this.emailId,
        telephone: '',
        message: '',
        agree_to_terms: '',
      },
    }
  },
  computed: {
    contactFormType(): string {
      return this.$store.state.contactFormType
    },
  },
  watch: {
    emailId(newVal) {
      this.form.email = newVal
    },
  },
  created() {
    if (this.contactFormType === 'お見積り') {
      this.form.type = 'お見積り'
    } else if (this.contactFormType === '無料トライアル') {
      this.form.type = '無償利用体験キャンペーン'
    } else if (this.contactFormType === '資料請求') {
      this.form.type = '資料請求'
    } else {
      this.form.type = ''
    }
  },
  methods: {
    async submit() {
      try {
        await fetch(
          'https://hooks.slack.com/services/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',
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
            },
            body: JSON.stringify({
              text: `*Threease LP contact:* \n *お問い合わせ内容:* ${this.form.type} \n *法人名（店舗名）:* ${this.form.company_name} \n *氏名（担当者）:* ${this.form.name} \n *メールアドレス:* ${this.form.email} \n *電話番号:* ${this.form.telephone} \n *ご質問・ご要望:* ${this.form.message}`,
            }),
          }
        )
        this.$emit('close')
        this.$router.push('/thanks')
      } catch (error) {}
    },
  },
})
