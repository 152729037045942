import { render, staticRenderFns } from "./BaseMailInput.vue?vue&type=template&id=2f33be5a&scoped=true&"
import script from "./BaseMailInput.vue?vue&type=script&lang=ts&"
export * from "./BaseMailInput.vue?vue&type=script&lang=ts&"
import style0 from "./BaseMailInput.vue?vue&type=style&index=0&id=2f33be5a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f33be5a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContactForm: require('/codebuild/output/src2428209011/src/threease_lp/components/ContactForm.vue').default,VTailwindModal: require('/codebuild/output/src2428209011/src/threease_lp/components/VTailwindModal.vue').default})
